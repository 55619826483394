<template>
	<div class="w100" id="app">
		<!-- 占位 -->
		<div class="w100 h80"></div>
		<!-- 其他页面顶部导航 -->
		<pageheader></pageheader>
		<div class="mh100">
			<router-view></router-view>
			<div class="w100 paddt80 " v-if="$store.state.path != '/About'"></div>
		</div>
		<div class="bg-F7F7F7">
			<div class="footer bg-DCFF03 center h80 font-blod fz12 co-010085 cure-pointer " style="align-items: flex-start;padding-top:10px" @click="winopen('https://beian.miit.gov.cn/')">
				<div class="flex-column center">
					<div class="">备案号：{{config.web_miitbeian}}</div>
					<div class="mart11">{{config.web_copyright}}</div>
				</div>
				<a @click.stop="stop" href="https://wap.scjgj.sh.gov.cn/businessCheck/verifKey.do?showType=extShow&serial=9031000020210407103343000007532863-SAIC_SHOW_310000-20221107142222619576&signData=MEUCIQDlbSz8rxrkeTH26rHl19Ydd/8LqXEm+AWbbcxKoEr74gIgLrlpfeYNPc08gbfFZdaNQKdjsie4mN0W7D891HjL8vo=">
					<img style="width: 18px;height: 18px;margin-left:8px" src="@/assets/img/zhengjian.png" />
				</a>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		ref,
		watch
	} from 'vue';
	import {
		useRoute
	} from 'vue-router';

	export default {
		name: "Index",
		setup() {
			
		},
		data() {
			return {			
				config:{
					web_miitbeian:"",
					web_copyright:""
				}
			}
		},
		created() {
				
		},
		mounted() {
			this.$req({
				method: 'get',
				url: '/api/config',
				
				success: res => {
					this.config.web_miitbeian =res.web_miitbeian;
					this.config.web_copyright =res.web_copyright;
				},
				fail: error => {}
			});
		},
		methods: {
			winopen(url){
				window.location.href = url
				console.log(url)
			},
			stop(){
				return
			},
			godetails(url, id) {
				
			    this.$router.push({
			        name: url,
			        query: {
			            id: id
			        }
			    });
			},
			
		}
	};
</script>

<style lang="less" scoped>
	.footer{
		display:flex;
		align-items:center;
		justify-content:center;
	}
	.em-box {
		width: 320px;
		height: 150px;
		padding: 13px;
		overflow-y: hidden;
		background: #FFFFFF;
		right: 72px;
		top: -141px;

		.em-box-inside {
			border-bottom: 1px solid #E6E6E6;

			.em-item {
				width: 30px;
				height: 30px;
				border-left: 1px solid #E6E6E6;
				border-top: 1px solid #E6E6E6;
				border-right: 1px solid #E6E6E6;
			}
		}
	}

	.send {
		width: 96px;
		height: 36px;
	}

	.se-02 {
		width: 24px;
		height: 24px;
	}

	.desc {
		width: 100%;
		height: 136px;
		outline: none;
		resize: none;
		border: 0;
		margin: 0;
		padding: 0;
	}

	.serve-head {
		width: 40px;
		height: 40px;
	}

	.content {
		max-width: 600px;
		padding: 13px 12px;
	}

	.consult-box {
		width: 1173px;
		height: 886px;
		background: #F9FAFD;
		box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.12);
		margin: auto;
		z-index: 10000;
		transition: all 0.3s;

		.consult-logo {
			width: 58px;
			height: 58px;
		}

		.se-01 {
			padding: 15px 11px;
		}

		.se-01-icon {
			width: 14px;
			height: 14px;
		}
	}

	.se_01 {
		width: 14px;
		height: 14px;
	}


	.user-operation {
		width: 320px;
		// height: 120px;
		background: #FFFFFF;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
		left: -45px;
		top: 80px;
		
	}

	.user-operation-item:hover {
		cursor: pointer;
		background: #F7F7FB;
		color: #010085;
	}
	.user-operation-item .clo{
		color: #010085;
		font-weight: 500;
		font-family: PingFang SC;
	}

	.code-popup-box {
		width: 340px;
		height: 118px;
		left: -350px;
		top: 26px;
		overflow: hidden;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
	}

	.code-popup {
		width: 300px;
		height: 118px;
		background: #FFFFFF;
		box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
		border-radius: 6px;
		padding: 0 20px;
		transition: all 0.3s;

		.code-pic {
			width: 60px;
			height: 60px;
		}
	}

	// 轮播
	.swiper {
		min-width: 1200px;
		height: 680px;

		.swiper-pic {
			height: 680px;
		}
	}

	// 顶部导航
	.nav_area {
		background: #FFFFFF;

		.nav {
			min-width: 1200px;
			margin: 0 auto;

			.logo {
				width: 44px;
				height: 44px;
			}

			.title_item {
				padding: 0 26px;
			}

			.title_item_01:hover span {
				color: #010085;
			}

			.i_01 {
				width: 10px;
				height: 10px;
				transition: all 0.3s;
			}

			.select {
				overflow: hidden;
				transition: all 0.3s;
				z-index: 1000001;
			}

			.select-item {
				height: 40px;
			}

			.select-item:hover {
				cursor: pointer;
				color: #010085;
			}

			.select_01 {
				height: 200px;
				top: 80px;
				box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.16);
			}

			.search-box {
				width: 252px;
				height: 34px;
				background: #F7F7F7;
				border-radius: 4px;

				.search_icon {
					width: 18px;
					height: 18px;
				}

				.search-input {
					outline: none;
					border: 0;
					background: #F7F7F7;
					margin-left: 12px;
				}
			}

			.notice {
				width: 22px;
				height: 22px;
			}

			.notice-num {
				padding: 0 6px;
				height: 14px;
				background: #EF3E00;
				border-radius: 7px;
				top: 25px;
				right: -10px;
			}

			.triangle {
				width: 27px;
				height: 15px;
				top: 76px;
				right: -3px;
			}

			.notice-area {
				// width: 24px;
				height: 418px;
				top: 80px;
				left: -170px;

				.notice-area-inside {
					box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
				}

				.scroll-area {
					border-radius: 8px 8px 0 0;

					.notice-item {
						padding-left: 10px;
						height: 54px;

						.point {
							width: 6px;
							height: 6px;
							background: #FE3000;
							border-radius: 50%;
							right: 4px;
						}

						.kefu-notice {
							width: 32px;
							height: 32px;
						}
					}
				}

				.remove-notice {
					height: 30px;
				}
			}
		}
	}

	input::-webkit-input-placeholder {
		color: #999999;
		font-size: 16px;
	}

	.rotate {
		transform: rotate(180deg);
	}

	.function {
		bottom: 200px;
		right: 80px;

		.index_bottom {
			width: 52px;
			height: 52px;
		}
	}

	.el-scrollbar__bar.is-horizontal {
		height: 0 !important;
		left: 0 !important;
	}

	.img_02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	textarea::-webkit-input-placeholder {
		color: #999999;
		font-size: 10px;
	}
</style>
